import Router from '../core/router/app';
import { lazy } from 'react';
const Login = lazy(() => import('../pages/Login'))
const Page404 = lazy(() => import('../pages/protected/404'));
const InvoiceExportPublic = lazy(() => import("../pages/Invoice"));
const ViewerFile = lazy(() => import("../pages/FileViewer"));
const HelloEndpoint = lazy(() => import("../pages/Hello"));
const router = new Router();


router.setRoute('/login', Login);
router.setRoute('/404', Page404);
router.setRoute('/invoice/:uiid', InvoiceExportPublic);
router.setRoute('/renderer/:url', ViewerFile);
router.setRoute('/hello', HelloEndpoint);       
export default router.build();  