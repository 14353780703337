import { hasData } from "../utils/data";

export default class Authorization {

    /* 
        TODO: Agregar un componente para
        paginas 404 y no redireccionar si no
        renderizar ese componente
    */
    constructor(rolesApp, tokenName, location) {
        this.location = location;
        this.rolesApp = rolesApp;
        this.tokenName = tokenName;
        this.types = [];
        this.rolAuthorize = null;
        this.redirection = null;
        this.setRoutesPublics(this.rolesApp.roles.default.pages);
    }

    setType(typeRol, routes, redirection) {
        if (typeRol === "default") {
            this.types.forEach(type => {
                type.routes = type.routes.filter(existingRoute => !routes.includes(this.normalizeRoute(existingRoute)));
            });
        }
        this.types.push({
            rol: typeRol,
            routes: routes.map(this.normalizeRoute),
            redirection: this.normalizeRoute(redirection)
        });
    }

    setRoutesPublics(routes) {
        this.routesPublics = routes.map(this.normalizeRoute);
    }

    setRedirection(redirection) {
        this.redirection = redirection;
    }

    setRolAuthorize(rol) {
        this.rolAuthorize = rol;
    }

    page() {
        return this.normalizeRoute(this.location);
    }

    normalizeRoute(route) {
        return route && route.endsWith("/") ? route : `${route}/`;
    }

    redirectTo(route) {
        window.location.href = route.endsWith("/") ? route.slice(0, -1) : route;
    }

    getDataByRolName(nameRol) {
        return this.types.find(type => type.rol === nameRol);
    }

    isRouteMatch(route1, route2) {
        if (!route1 || !route2) return false;  // Verificar que ambas rutas estén definidas

        const segments1 = route1.split('/').filter(Boolean);
        const segments2 = route2.split('/').filter(Boolean);

        return segments1.every((segment, i) =>
            segment && (segment.startsWith(':') || (segments2[i] && segments2[i].startsWith(':')) || segment === segments2[i])
        );
    }

    isPublicRoute(route) {
        return this.routesPublics.some(publicRoute => this.isRouteMatch(this.normalizeRoute(publicRoute), route));
    }

    run() {
        this.initializeRoles();

        if (!hasData(localStorage.getItem(this.tokenName))) {
            this.setRolAuthorize("default");
        }

        const currentPage = this.page();
        let notFound = true;

        if (this.isPublicRoute(currentPage)) return;

        this.types.some(type => {
            if (type.rol === this.rolAuthorize) {
                notFound = false;

                const currentSegments = currentPage.split('/').filter(Boolean);
                if (type.rol !== "default") currentSegments.shift();

                const isIn = type.routes.some(route => {
                    const routeSegments = route.split('/').filter(Boolean);
                    return routeSegments.every((seg, i) => seg && (seg.startsWith(':') || seg === currentSegments[i]));
                });

                if (!isIn) {
                    this.redirectTo(type.redirection);
                    return true;
                }
            }
        });

        if (notFound) {
            const defaultData = this.getDataByRolName("default");
            if (defaultData && !this.page().endsWith(defaultData.redirection)) {
                this.redirectTo(defaultData.redirection);
            }
        }
    }

    initializeRoles() {
        this.rolesApp.getJustNameRoles().forEach(rolName => {
            const { name, pages, defaultRedirection } = this.rolesApp.get(rolName);
            this.setType(name, pages, defaultRedirection);
        });
    }
}
